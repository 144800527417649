<template>
  <v-breadcrumbs
      style="padding-top: 15px; padding-bottom: 0;"
      :items="crumbs"
  >
    <template v-slot:divider>
<!--      >-->
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  async created() {
  },
  data() {
    return {
      crumbs: null
    }
  },

  }
</script>

<style scoped>

</style>
