<template>
  <v-container fluid class="gallery_container">
    <div class="product-title">Reprints and merchandise</div>
    <div>
      <v-container fluid class="gallery_container">
        <v-row no-gutters>
          <v-col
              v-for="(item) in shop_items"
              :key="item.id"
              cols="12"
              sm="3"
          >
        <v-sheet class="ma-2 pa-2" v-if="shop_items">
          <v-card style="max-width: 200px; padding-bottom: 0;margin-bottom: 0">
            <div class="gallery_items_header pt-5">{{item.name}}</div>
                <v-img
                    @click="setSelectedItem(item.id)"
                    style="margin-left: auto;margin-right: auto"
                    class="bg-white"
                    width=200px
                    :aspect-ratio="1"
                    :src="item.thumbnail_url"
                ></v-img>


        </v-card>
        </v-sheet>
        </v-col>

        </v-row>
      </v-container>
    </div>
  </v-container>

</template>

<script>
import {dataService} from "@/DataService";

export default {
  name: "MerchGallery",
  components: {},
  data() {
    return {
      shop_items: [],
      product: {},
      products: {},
      combined: [],
      product_id: 0,
      product_external_id: 0
    }
  },
  created() {
    this.getProductItems();

  },
  methods: {
    setSelectedItem(item_id) {
      this.product_id = item_id
      console.log("THIS PRODUCT IT: ", this.product_id)
      console.log("SELECTED ITEM: ",item_id)
      this.$router.push({name: 'item-template', params: {item_id: this.product_id}})
    },
    async getProductItems() {
      try {
        this.shop_items = await dataService.getProducts();
        console.log('getProductItems: ', this.shop_items)
      } catch (e) {
        console.log(e)
      }
    },
    // async getCombinedProducts() {
    //   try {
    //     this.combined = await dataService.getCombinedProductInfo();
    //     console.log('MerchGallery shop item: ', this.combined)
    //
    //   } catch (e) {
    //     console.log(e)
    //   }
    //   console.log("This product after has been returned: ", this.combined)
    // },
    // async getProductDetails(id) {
    //   console.log("Getting product details for " + id)
    //   try {
    //     this.product = await dataService.getProduct(id);
    //     console.log('MerchGallery shop item: ', this.product)
    //
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }
  }
}
</script>

<style scoped>
.gallery_container {
  width: 1200px;
}

.gallery_items_text {
  font-size: .85em;
  color: darkmagenta;
  text-decoration: none;
}

.gallery_items_header {
  font-size: .85em;
  color: darkblue;
  text-decoration: none;
}

.gallery_items_sold {
  font-size: 1em;
  color: darkslategrey;
  text-decoration: none;
}</style>
