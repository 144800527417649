<template>
  <div v-if="product">
    <div class="wrapper">
      <button class="btn btn--minus" @click="changeCounter('-1')" type="button" name="button">
        -
      </button>
      <input class="quantity" type="text" name="name" :value="counter">
      <button class="btn btn--plus" @click="changeCounter('1')" type="button" name="button">
        +
      </button>
    </div>
<!--    <v-row>-->
<!--    <v-col><v-btn  variant="outlined" class="btn btn-outline-secondary " :class="loading?'disabled':''" @click="addOrRemove(-1)"  type="button" id="button-addon1">-</v-btn></v-col>-->
<!--      <v-col><v-text-field outlined type="number" v-model="qty"  disabled style="max-width:60px"></v-text-field></v-col>-->
<!--        <v-col><v-btn  variant="outlined" class="btn btn-outline-secondary" :class="loading?'disabled':''"  @click="addOrRemove(1)"  type="button" id="button-addon1">+</v-btn></v-col>-->
<!--    </v-row>-->
  </div>
</template>
<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default{
  name:'CartAddRemove',
  props:['product'],
  data(){
    return{
      counter: 0,
      qty:0,
      loading:false,
      productItem: {}
    }
  },
  methods:{
    changeCounter: function(num){
      this.counter += +num
      console.log(this.counter)
      !isNaN(this.counter) && this.counter > 0 ? this.counter : this.counter = 0;
    },
    async addOrRemove(number){
      this.loading = true
      if(number === 1 || number === 'undefined'){ //add
        if(this.qty < 10){
          this.qty++
          this.product.product.qty = this.qty
          console.log("PRODUCT TO ADD from AddCartRemove: ", this.product)
          await this.$store.commit('updateCart',{product:this.product})
          toast.success('cart updated')
        }else{
          toast.warning('You reached the limit')
        }
      }
      if( number == -1){ //remove
        if(this.qty > 1){
          this.qty--
          this.product.product.qty = this.qty
          await this.$store.commit('updateCart',{product:this.product})
          toast.success('cart updated');
        }else{
          toast.warning('You reached the limit');
        }
      }

      this.loading = false
    }
  },
  computed: {
    localProduct: {
      get() {
        return this.product
      },
      set(newValue) {
        this.$emit('update:product', newValue)
      },
    },
  },
  created(){
    // this.qty = this.product.qty
    // this.productItem = this.localProduct
    // console.log("LOCAL PRODUCT: ", this.productItem)
  }
}
</script>
<style>
.plus-minus input {
  max-width: 50px;
}

/* Product Quantity */
.wrapper {
  height: 30px;
  display: flex;
}
.quantity {
  -webkit-appearance: none;
  border: none;
  text-align: center;
  width: 30px;

  font-size: 16px;
  color: #43484D;
  font-weight: 300;
  border: 1px solid #E1E8EE;
}

.btn {
  border: 1px solid #E1E8EE;
  width: 30px;
  background-color: #E1E8EE;
  /*   border-radius: 6px; */
  cursor: pointer;
}
button:focus,
input:focus {
  outline:0;
}
</style>
