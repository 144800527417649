
const {format} = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2
})

export default {
    filters: {
        formatMoney(amount, defaultValue = '$0.00') {
            console.log("VAL FROM FORMATTER: ", amount)
            let formattedMoney = '';
            try {
               if (typeof amount !== undefined && amount && !isNaN(amount)) {
                   formattedMoney = this.addParenthesis(format(amount))
               } else {
                   formattedMoney = defaultValue
               }
            } catch (e) {
               console.log(e)
            }
            return formattedMoney;
        },

    addParenthesis(val) {
        console.log("VAL FROM FORMATTER: ", val)
        const temp = val.replace('$','')
        if(temp < 0){
            return "$(" + temp + ")"
        } else {
            return val
        }
    }
    },
}
