<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col
            cols="12"
            sm="3">
          <a href="https://www.amazon.com/Glass-Jim-W-Coleman/dp/141843051X" target="_blank">
          <v-img
              style="margin-left: 2em"
              class="float-md-right"
              width=400px
              :aspect-ratio="1"
              :src="`/site-images/glass-cover-jimwcoleman.jpg`"
          ></v-img></a>
        </v-col>
        <v-col
            cols="12"
            sm="3">
          <a href="https://www.amazon.com/Level-Heads-XE-James-Coleman/dp/1420875876" target="_blank">
          <v-img
              style="margin-left: 2em"
              class="float-md-right"
              width=400px
              :aspect-ratio="1"
              :src="`/site-images/level-heads-cover-jimwcoleman.jpg`"
          ></v-img></a>
        </v-col>
        <v-col
            cols="12"
            sm="3">
          <a href="https://www.amazon.com/Secret-Things-James-Coleman/dp/1410753573"  target="_blank">
          <v-img
              style="margin-left: 2em"
              class="float-md-right"
              width=400px
              :aspect-ratio="1"
              :src="`/site-images/secret-things-cover.jpg`"
          ></v-img></a>
        </v-col>
        <v-col
            cols="12"
            sm="3">
          <a href="https://www.amazon.com/Omens-James-Coleman/dp/1414070527"  target="_blank">
          <v-img
              style="margin-left: 2em"
              class="float-md-right"
              width=400px
              :aspect-ratio="1"
              :src="`/site-images/omens-cover-jimwcoleman.jpg`"
          ></v-img></a>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
            cols="12"
            sm="3">

        </v-col>
        <v-col
            cols="12"
            sm="3">

        </v-col>      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "BooksJWC"
}
</script>

<style scoped>

</style>
