import {createStore} from 'vuex'
/*eslint-disable*/
/* https://www.youtube.com/watch?v=AssWYh6ka0I last: 9:30 */
export default createStore({
    state: {
        cart: [],
        cartTotal: 0
    },
    mutations: {
        async initialiseStore(state) {
            if(localStorage.getItem('cart')){
                state.cart = JSON.parse(localStorage.getItem('cart'))
            }
            if(localStorage.getItem('cartTotal')){
                state.cartTotal = parseFloat(localStorage.getItem('cartTotal'))
            }
            return true;
        },
        addRemoveCart(state, payload) {
            console.log("PAYLOAD TO ADD TO CART: ", payload)
            payload.toAdd ?
                state.cart.push(payload.product) : state.cart = state.cart.filter(function (obj) {
                    console.log("OBJ: ", obj)
                    return obj.variant_id !== payload.product.product.variant_id
                })
            state.cartTotal = state.cart.reduce((accumulator, object) => {
                return parseFloat(accumulator) + parseFloat(object.retail_price * object.qty)
            },0)
            console.log("state.cart from store index: ", state.cart)
            localStorage.setItem('cartTotal',JSON.stringify(state.cartTotal))
            localStorage.setItem('cart',JSON.stringify(state.cart))

            console.log(state.cartTotal)
            console.log(state.cart)
        },
        updateCart(state,payload){
            console.log("PAYLOAD", payload)
            console.log("state", state)
            state.cart.find(o => o.variant_id === payload.variant_id).qty = payload.qty;

            state.cartTotal = state.cart.reduce((accumulator, object)=>{
                return parseFloat(accumulator) + parseFloat(object.retail_price * object.qty);
            },0)
            localStorage.setItem('cartTotal',JSON.stringify(state.cartTotal));
            localStorage.setItem('cart',JSON.stringify(state.cart));

        }
    },
    actions: {},
    modules: {},
})
