import { createApp } from 'vue'
import store from "./store"
import App from './App.vue'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// https://onycat.com/vue-easy-lightbox/guide/#using-umd-in-browser
// import VueEasyLightbox from 'vue-easy-lightbox'

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import router from './router'

Amplify.configure(awsExports);

const cors = require ('cors')

// Pinia cart: https://github.com/andrew-zachary/big-deals/blob/main/src/stores/cart.js



// const allowedOrigins = ['http://localhost:8080','http://24.113.234.205:8080']


const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'light'
    }
})


// createApp(App).use(router).use(vuetify).use(cors).use(pinia).mount('#app')
createApp(App).use(router).use(vuetify).use(cors).use(store).mount('#app')
