<template>
  <div>
    <h2>Commissions</h2>
    <v-img
        style="margin-left: 2em"
        class="float-md-right"
        width=400px
        :aspect-ratio="1"
        :src="`/site-images/commissions.jpg`"
    ></v-img>
    <div style="text-align: justify">
    <p  class="header">What does it mean to commission artwork? In simple terms, it basically means you hire me to produce a piece of artwork for you - a pencil sketch, a pen and ink drawing or a painting.</p>

    <p>I am hired most often to produce portraits of loved ones who have passed or family members who are still alive, but I can also memorialize a favorite singer, actor, friend, etc. Most recently, I've been commissioned to draw a baby. Framed artwork will, in optimal conditions, still be around long after we all are gone and will look just as amazing then as when it was first created. It's a perfect gift and a unique, personal way to memorialize or acknowledge a person, place or thing.</p>

    <p>I specialize in portraits, figure studies, science fiction, Egyptian, Native American and more. I typically avoid drawing caricatures and cartoons. Feel free to open a dialogue with me about your ideas and we'll go from there.</p>

    <p class="header">How it works:</p>
    <br>Commissioning a custom art piece is simple. You provide a model, or several good, high-resolution photos of the subject. From there, I will evaluate your proposal and let you know if it's something I can do. Then, I work up a sketch for your approval and then I proceed to start the final product.

    <p class="header">Payment:</p>
    <br>One half of the agreed-upon payment is due up front as I start to work up the proposal and the initial sketch for your approval. When the final art piece is ready for delivery, the remaining half is due. I often build shipping into my price but for larger works, that will incur additional costs, some which may not be known until the piece is packaged and ready. Generally, though, it's covered in my fee.

    <p class="header">How much does it cost?</p>
    <br>That depends on the size and complexity. My work is generally very detailed and large pieces can take between 80 and 100 hours to complete! But most commissioned work is not that complex. At an open rate of $50 per hour, an 8x10 portait will cost between $200 (pencil) and between $500 and $800 (ink/paint). When you consider that these are hand-drawn and, with proper care, can be passed along from one generation to the next and on down the line, it becomes a worthy investment. I'm also open to barter.

    <p class="header">How accurate are my reproductions?</p>
    <br>It's a fact that no two people see a person or object the same way. And we, as individuals, are more likely to say something doesn't look like us when, in fact, it does. There are examples of my work on this page and all over this website, so you can be the judge of that.

    <p>When starting your project, I always provide you with a rough sketch so you can judge the likeness before it gets inked. If it's not quite right, I can rework it. If it just never gets there, it's not like we didn't try. I can think of only two cases where I was unable to accurately capture a person's face to canvas and I've been doing this for a long, long time. I am my own harshest critic, so even when everyone else seems to like something I'm working up, I sometimes don't and will rework it and rework it until I'm satisfied that it's the best work I am capable of doing.</p>

    <p>That's what I will always deliver to you.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommissionsJWC"
}
</script>

<style scoped>
p {
  margin-top: 1em;
}
h2{
  text-align: left;
}
.header {
  font-family: sans-serif;
  font-size: 1.25em;
  color:olivedrab;
}
</style>
