<template>
  <div id="ListMerch">

    <authenticator>
      <template v-slot="{ user, signOut }">
        <h1>Hello {{ user.username }}!</h1>
        <button @click="signOut">Sign Out</button>
      </template>
    </authenticator>

    <h3>Merchandise</h3>

      <v-table>
        <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Url
          </th>
          <th class="text-left">
            Actions
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in merchandise_list"
            :key="item.id"
        >
          <td class="text-left">
            <router-link :to="{name:'merch-edit-item', params: {id: item.id}}">
              {{ item.title }}
            </router-link></td>
          <td class="text-left">{{ item.external_url }}</td>
          <td class="text-left">
            <router-link :to="{name:'merch-edit-item', params: {id: item.id}}" style="text-decoration: none"><v-icon icon="mdi-vuetify">mdi-pencil</v-icon></router-link>
            <v-icon icon="mdi-vuetify" @click="deleteMerchItem(item.id)">mdi-delete</v-icon>
          </td>
        </tr>
        </tbody>
      </v-table>

  </div>
</template>
<script setup>
import { Authenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
</script>

<script>
import {API, Auth} from 'aws-amplify';
import {listGalleries} from '@/graphql/queries';
import {deleteGallery} from "@/graphql/mutations";

export default {
  title: 'ListMerch',
  async created() {
    try {
      await this.getMerchandise();
    } catch (e) {
      console.log("Unable to get Merchandise - ListMerch: ", e)
    }
  },
  data() {
    return {
      current_user: {},
      merches: [],
      merchandise_list: [],
      isAdmin: false,
      headers: [
        {text: "Title", value: "title"},
        {text: "Year", value: "year", align: "end"}
      ],
    };
  },
  async mounted() {
    try{
    this.current_user_computed = await Auth.currentAuthenticatedUser();
    this.isAdmin = !!this.current_user_computed.signInUserSession.idToken.payload['cognito:groups'].includes("Admin");
    return this.isAdmin;} catch (err) {
      console.log("Error in list merchandise authentication check: ", err)
    }
  },
  methods: {

    navigateToGalleryItem(event, info) {
      this.$router.push({name: "merch-edit-item", params: {id: info.item.id}});
    },

    async deleteMerchItem(id) {
      console.log("Delete id: ", id)
      const merchDetails = {
        id: id,
      };
      const deletedMerch = await API.graphql({
        query: deleteGallery,
        variables: {input: merchDetails}
      });
      if (deletedMerch)
      await this.$router.push({ name: 'list-merch-items' })
    },

    async getMerchandise() {
      // this.current_user = await Auth.currentUserInfo();
      this.current_user = await Auth.currentAuthenticatedUser();
      //This gives an array that contains the cognito user groups to test access.
      console.log("current user: ", this.current_user.signInUserSession.idToken.payload['cognito:groups']);
      const merches = await API.graphql({
        query: listGalleries,
        variables: {filter: {type: {eq: "Merch"}}}
      });
      this.merchandise_list = merches.data.listGalleries.items;
    }
  }
};
</script>

<style>
.theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #6fbd44;
}

.v-input--is-focused .v-input__slot {
  border: 2px solid #ffffff !important;
  border-bottom-color: rgba(0, 0, 0, 0.38) !important;
}

</style>
