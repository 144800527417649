<template>

  <div style="width:500px">
<div v-if="successMessage.length === 0">
        <v-form @submit.prevent="submitForm">
          <v-text-field label="Name"
             dense v-model="name"
             variant="underlined"
             density="compact"
             color="white"
             placeholder="Your name"
             bg-color="whitesmoke"></v-text-field>
          <v-text-field label="Email"
             dense v-model="email"
             variant="underlined"
             density="compact"
             color="white"
             placeholder="Your email"
             bg-color="whitesmoke"></v-text-field>
          <v-text-field label="Subject"
              dense v-model="subject"
              variant="underlined"
              density="compact"
              color="white"
              placeholder="Subject"
              bg-color="whitesmoke"></v-text-field>
          <v-textarea label="Message"
             dense v-model="message"
             variant="underlined"
             density="compact"
             color="white"
             placeholder="Your inquiries and comments are welcomed and appreciated."
             bg-color="whitesmoke"></v-textarea>
          <v-btn type="submit" color="primary">Send Message</v-btn>
        </v-form>
</div>
    <div><strong>{{successMessage}}</strong></div>
    <br>
    <div>
      <p>
    Jim W. Coleman Studios
    <br />PO Box 723
    <br />Port Orchard, WA 98366
      </p>
    </div>
  </div>
</template>



<script>
const WEB3FORMS_ACCESS_KEY = "a5ecfbb6-5180-4c88-89d7-318f96772ef4";
export default {
  name: "ContactJWC",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      subject: "",
      successMessage: ""
    }
  },
  methods: {
    resetFormFields() {
      this.name = ""
      this.email = ""
      this.message = ""
      this.subject = ""
    },
    async submitForm() {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          access_key: WEB3FORMS_ACCESS_KEY,
          name: this.name,
          email: this.email,
          message: this.message,
        }),
      });
      const result = await response.json();
      if (result.success) {
        this.resetFormFields();
        this.successMessage = "Your message has been sent.  Thank you for your interest in my work."
        console.log(result);
      }
    },
  }
}
</script>

<style scoped>

</style>
