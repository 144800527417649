import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/components/Home.vue'
import Commissions from "@/components/CommissionsJWC";
import Books from "@/components/BooksJWC";
import Contact from "@/components/ContactJWC";
import DisplayGalleryItem from "@/components/DisplayGalleryItem";
import CherokeeArt from "@/components/CherokeeArt";
import MainGallery from "@/components/MainGallery";
import MerchGallery from "@/printful/MerchGallery";
import MerchProductTemplate from "@/printful/MerchProductTemplate";
import CartView from '/src/cart/CartView.vue'

import EditGalleryItem from "@/admin/EditGalleryItem";
import EditMerchItem from "@/admin/EditMerchItem";
import ListArtwork from "@/admin/ListArtwork";
import ListMerch from "@/admin/ListMerch";
import AdminHome from "@/admin/AdminHome";


const routes = [
  {
      path:'/',
      name: 'Home',
      component: Home,
  },
  {
      path:'/Commissions',
      name: 'Commissions',
      component: Commissions
  },
  {
      path:'/Books',
      name: 'Books',
      component: Books
  },
  {
      path:'/Contact',
      name: 'Contact',
      component: Contact
  },
  {
      path: '/CherokeeArt',
      name: 'Cherokee Art',
      component: CherokeeArt
  },
  {
      path:'/gallery-display-item:id',
      name: 'gallery-display-item',
      params: true,
      component: DisplayGalleryItem,
      meta: {
          title: "Gallery items ",
          resource: "DisplayGalleryItem",
          breadCrumb: "Gallery"
      }
  },
  {
      path:'/gallery/add-item',
      name: 'gallery-add-item',
      component: EditGalleryItem
  },
  {
      path:'/gallery/edit-item/:id',
      name: 'gallery-edit-item',
      params: true,
      component: EditGalleryItem,
  },
  {
      path:'/merch/add-item',
      name: 'merch-add-item',
      component: EditMerchItem
  },
  {
      path:'/merch/edit-item/:id',
      name: 'merch-edit-item',
      params: true,
      component: EditMerchItem,
  },
  {
      path:'/admin/ListMerch',
      name:'list-merch-items',
      component: ListMerch
  },
  {
    path:'/admin/ListArtwork',
    name:'list-gallery-items',
    component: ListArtwork
  },

  {
    path:'/Edit',
    name:'',
    redirect: { name: 'list-gallery-items' }
  },
  {
    path:'/Products/Merch',
    name:'Merchandise',
    component: MerchGallery
  },
  {
    path:'/Products/Item/:item_id',
    name:'item-template',
    component: MerchProductTemplate

  },
  {
    path:'/Galleries/:name',
    name:'Galleries',
    component: MainGallery
  },
  {
    path:'/Admin',
    name:'admin',
    component: AdminHome
  },
  {
    path:'/cart',
    name:'cart-view',
    component:CartView
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/NotFound.vue'),
    hidden: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
