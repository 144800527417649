<template>
  <div>
    <v-container fluid>
        <v-container fluid class="gallery_container">
          <v-row no-gutters>
            <v-col v-for="item in limitHomePaintingsList" :key="item.title">
              <v-sheet class="pa-2 ma-0">
                <v-card style="max-width: 220px; padding-bottom: 0;margin-bottom: 0;margin-left: auto;margin-right: auto">
                <v-card-text></v-card-text>
                  <router-link :to="{name:'gallery-display-item', params: {id: item.id }}" style="text-decoration: none">
                    <v-img
                      style="margin-left: auto;margin-right: auto"
                      class="bg-white"
                      width=200px
                      :aspect-ratio="1"
                      :src="`https://jwc2023-dev52754-main.s3.amazonaws.com/public/${item.file_name}`"
                  ></v-img></router-link>
                  <div class="gallery_items_header"><i>{{ item.title }}</i>, {{ item.year }}</div>
                  <div class="gallery_items_text">{{ item.art_media }}</div>
                  <div class="gallery_items_text">{{ item.artist }}</div>
                  <div class="gallery_items_text"  v-if="item.disposition !== 'Gifted' && item.disposition !== 'SOLD'">${{ item.original_price }}</div>
                  <div v-if="item.disposition === 'SOLD'" class="gallery_items_sold"><strong>{{ item.disposition }}</strong></div>
                  <div v-else class="gallery_items_text">{{ item.disposition }}</div>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row style="padding-top: 0px">
            <v-col>
              <div style="text-align: right; padding-top: 0em">
                <router-link :to="{name: 'Galleries', params: {name: 'Painting'}}" class="topnav">More paintings</router-link> &raquo;
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-container>

      <v-container fluid class="gallery_container">
          <v-row no-gutters>
            <v-col v-for="item in limitHomePenAndInksList" :key="item.title"
            >
              <v-sheet class="pa-2 ma-0">
                <v-card style="max-width: 220px; padding-bottom: 0;margin-bottom: 0;margin-left: auto;margin-right: auto">
                  <v-card-text></v-card-text>
                  <router-link :to="{name:'gallery-display-item', params: {id: item.id, pk: item.pk}}" style="text-decoration: none">
                    <v-img
                        style="margin-left: auto;margin-right: auto"
                        class="bg-white"
                        width=200px
                        :aspect-ratio="1"
                        :src="`https://jwc2023-dev52754-main.s3.amazonaws.com/public/${item.file_name}`"
                    ></v-img></router-link>
                  <div class="gallery_items_header"><i>{{ item.title }}</i>, {{ item.year }}</div>
                  <div class="gallery_items_text">{{ item.art_media }}</div>
                  <div class="gallery_items_text">{{ item.artist }}</div>
                  <div class="gallery_items_text"  v-if="item.disposition !== 'Gifted' && item.disposition !== 'SOLD'">${{ item.original_price }}</div>
                  <div v-if="item.disposition === 'SOLD'" class="gallery_items_sold"><strong>{{ item.disposition }}</strong></div>
                  <div v-else class="gallery_items_text">{{ item.disposition }}</div>
                </v-card>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row style="padding-top: 0px">
            <v-col>
              <div style="text-align: right; padding-top: 0em" class="topnav">
                <router-link :to="{name: 'Galleries', params: {name: 'Pen-and-ink'}}" class="topnav">More pen-and-ink</router-link> &raquo;</div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-container>

      <v-container fluid class="gallery_container">
        <v-row no-gutters>
          <v-col v-for="item in limitHomePencilList" :key="item.title"
          >
            <v-sheet class="pa-2 ma-0">
              <v-card style="max-width: 220px; padding-bottom: 0;margin-bottom: 0;margin-left: auto;margin-right: auto">
                <v-card-text></v-card-text>
                <router-link :to="{name:'gallery-display-item', params: {id: item.id, pk: item.pk}}" style="text-decoration: none">
                  <v-img
                      style="margin-left: auto;margin-right: auto"
                      class="bg-white"
                      width=200px
                      :aspect-ratio="1"
                      :src="`https://jwc2023-dev52754-main.s3.amazonaws.com/public/${item.file_name}`"
                  ></v-img></router-link>
                <div class="gallery_items_header"><i>{{ item.title }}</i>, {{ item.year }}</div>
                <div class="gallery_items_text">{{ item.art_media }}</div>
                <div class="gallery_items_text">{{ item.artist }}</div>
                <div class="gallery_items_text"  v-if="item.disposition !== 'Gifted' && item.disposition !== 'SOLD' && item.disposition !== 'Private collection'">${{ item.original_price }}</div>
                <div v-if="item.disposition === 'SOLD'" class="gallery_items_sold"><strong>{{ item.disposition }}</strong></div>
                <div v-else class="gallery_items_text">{{ item.disposition }}</div>
              </v-card>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row style="padding-top: 0px">
          <v-col>
            <v-col>
              <div style="text-align: right; padding-top: 0em" class="topnav">
                <router-link :to="{name: 'Galleries', params: {name: 'Celeb'}}" class="topnav">More autographs</router-link> &raquo;</div>
            </v-col>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-container>

    </v-container>

    <div class="col-12 bg-light">
    <router-view/>
    </div>
  </div>
</template>

<script>
// menubar issues: https://stackoverflow.com/questions/57029493/bind-drawer-state-in-vuetify-when-nav-drawer-and-app-bar-is-different-components
import {API} from "aws-amplify";
import {listGalleries}  from "@/graphql/queries";

export default {
  name: 'App',
  baseImageUrl: '@/assets/gallery/paintings/',
  components: {},
  async created() {
    await this.getArtworks();
  },
  data: () => ({
    baseImageUrl: '@/assets/gallery/paintings/',
    paintings_list: [],
    celeb_list: [],
    pen_and_inks_list: [],
    merchandise_list: [],
    limitation_list: 4,
    order: 0,
    group: null,
    items: [
      {
        title: 'Foo',
        value: '<a href="#">Link</a>',
      },

    ]
  }),
  computed: {
    // https://medium.com/lapis/how-to-implement-dynamodb-single-table-designs-with-aws-amplify-appsync-lambda-8838e86b2f71
    limitHomePaintingsList() {
      return this.paintings_list.slice(0, 4)
    },
    limitHomePenAndInksList() {
      return this.pen_and_inks_list.slice(0, 4)
    },
    limitMerchandiseList() {
      return this.merchandise_list.slice(0, 4)
    },
    limitHomePencilList() {
      return this.celeb_list.slice(0, 4)
    },
    calculateWidth(item) {
      if (item.width > item.height) {
        return item.width
      } else return item.height;
    }
  },
  methods: {
    getImage(imagePath) {
      return require(this.baseImageUrl + imagePath);
    },
    async getArtworks() {
      // This whole section could be refactored to one database call and parsed on the client.


      try {

      const paintings = await API.graphql({
        query: listGalleries,
      });
      this.paintings_list = paintings.data.listGalleries.items.filter((item) => item.tag === "Painting" && item.location.toLowerCase() === 'home');
        // state.artworks = artworks.data.listGalleries.items.filter((item) => item.tag !== "Merch")
      } catch (e) {
        console.log("Error getting gallery by tag: ", e)
      }

      try{
      const pen_and_inks = await API.graphql({
        query: listGalleries,
        variables: {
          tag: "Pen-and-ink"
          // filter: { SK: {beginsWith: "TAG"}, and: {PK: {beginsWith: "ART"}, and: {SK: {contains: "Pen-and-ink"}}}}
        },
      });
      this.pen_and_inks_list = pen_and_inks.data.listGalleries.items.filter((item) => item.tag === "Pen-and-ink"  && item.location.toLowerCase() === 'home');
    } catch (e) {
      console.log("Unable to get pen-and-inks - Home component: ", e)
    }

      try{
        const celeb = await API.graphql({
          query: listGalleries,
          variables: {
            tag: "Pencil"
            // filter: { SK: {beginsWith: "TAG"}, and: {PK: {beginsWith: "ART"}, and: {SK: {contains: "Pen-and-ink"}}}}
          },
        });
        this.celeb_list = celeb.data.listGalleries.items.filter((item) => item.tag === "Celeb"  && item.location.toLowerCase() === 'home');
      } catch (e) {
        console.log("Unable to get Pencil sketches - Home component: ", e)
      }

      try{
      const merchandise = await API.graphql({
        query: listGalleries,
      });
      this.merchandise_list = merchandise.data.listGalleries.items.filter((item) => item.tag === "Merch");
    } catch (e) {
      console.log("Unable to get merchandise - Home component: ", e)
    }
    }
  }
}
</script>

<style>
.gallery_container {
  width: 1200px;
}
.gallery_items_text {
  font-size: .85em;
  color: darkmagenta;
  text-decoration: none;
}
.gallery_items_header {
  font-size: .85em;
  color: darkslategrey;
  text-decoration: none;
}
.gallery_items_sold {
  font-size: 1em;
  color: darkslategrey;
  text-decoration: none;
}
</style>
