/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createGallery = /* GraphQL */ `
  mutation CreateGallery(
    $input: CreateGalleryInput!
    $condition: ModelGalleryConditionInput
  ) {
    createGallery(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      description
      url
      file_name
      external_url
      year
      art_width
      art_height
      disposition
      artist
      art_media
      owner
      original_price
      sale_price
      reprint_price
      reprints_source
      commissioned_by
      memo
      title
      location
      tag
      relates_to
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGallery = /* GraphQL */ `
  mutation UpdateGallery(
    $input: UpdateGalleryInput!
    $condition: ModelGalleryConditionInput
  ) {
    updateGallery(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      description
      url
      file_name
      external_url
      year
      art_width
      art_height
      disposition
      artist
      art_media
      owner
      original_price
      sale_price
      reprint_price
      reprints_source
      commissioned_by
      memo
      title
      location
      tag
      relates_to
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGallery = /* GraphQL */ `
  mutation DeleteGallery(
    $input: DeleteGalleryInput!
    $condition: ModelGalleryConditionInput
  ) {
    deleteGallery(input: $input, condition: $condition) {
      id
      PK
      SK
      type
      description
      url
      file_name
      external_url
      year
      art_width
      art_height
      disposition
      artist
      art_media
      owner
      original_price
      sale_price
      reprint_price
      reprints_source
      commissioned_by
      memo
      title
      location
      tag
      relates_to
      createdAt
      updatedAt
      __typename
    }
  }
`;
