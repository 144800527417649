<template>
  <v-container fluid class="gallery_container">
    <div v-for="(item, index) in state.product" :key="index">
      <div v-if="index < 1">
        <v-row no-gutters="">
          <v-col               cols="12"
                               sm="3">
            <v-img
                style="margin-left: auto;margin-right: auto"
                class="bg-white"
                width=200px
                :aspect-ratio="1"
                :src="item.data.result.sync_product.thumbnail_url"
            ></v-img>
          </v-col>
          <v-col
              style="margin-top: auto; margin-bottom: auto"
              cols="12"
                 sm="6">
            <div v-for="(variant) in item.data.result.sync_variants" :key="variant.id" style="text-align: left">
              <div class="mt-2">{{ variant.name }}: ${{ variant.retail_price }}</div>
              <div><CartBTN :product = "variant"/></div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import {dataService} from "@/DataService";
import {onBeforeMount, reactive} from "vue";
import {useRoute} from 'vue-router'
import CartBTN from '../cart/CartBTN.vue'

export default {
  name: "MerchProductTemplate.vue",
  components: {CartBTN},
  setup() {
    let id = ''
    const state = reactive({
      product: {},
    })
    const route = useRoute()


    onBeforeMount(async () => {
      id = route.params.item_id;
      getProduct(id);


    });

    async function getProduct(id) {
      try {
        // state.product = await dataService.getProduct(id);
        state.product = await dataService.getCombinedProductInfo(id)
        console.log('MerchGallery shop item: ', id)

      } catch (e) {
        console.log(e)
      }
      console.log("This product after has been returned: ", state.product)

    }

    // async function getCombinedProducts() {
    //   let combined = []
    //   try {
    //     combined = await dataService.getCombinedProductInfo();
    //     console.log('MerchGallery shop item: ', combined)
    //
    //   } catch (e) {
    //     console.log(e)
    //   }
    //   console.log("This product after has been returned: ", combined)
    // }
    //
    // async function getProductDetails(id) {
    //   console.log("Getting product details for " + id)
    //   try {
    //     this.product = await dataService.getProduct(id);
    //     console.log('MerchGallery shop item: ', this.product)
    //
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }
    return {id, route, state}
  }

}
</script>

<style scoped>

</style>
