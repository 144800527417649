<template>

  <authenticator
      hideSignUp={true}
  >
    <template v-slot="{ user, signOut }">
      <h1>Hello {{ user.username }}!</h1>
      <button @click="signOut">Sign Out</button>
    </template>
  </authenticator>

  <div class="icon-drawer" v-if="isAdmin"><v-icon>mdi-plus</v-icon> <router-link :to="{name: 'gallery-add-item'}" style="text-decoration: none">Add gallery item</router-link></div>

  <div class="icon-drawer" v-if="isAdmin"><v-icon>mdi-view-list</v-icon> <router-link to="/admin/ListArtwork" style="text-decoration: none">List items</router-link></div>

</template>

<script setup>
import {Authenticator} from '@aws-amplify/ui-vue';
</script>

<script>
import {Auth} from 'aws-amplify';

export const getCurrentUser = async () => {
  try {
    const userInfo = await Auth.currentAuthenticatedUser({bypassCache: true})
    console.log("User info: ", userInfo)
    if (userInfo) {
      // returns a Array<strings>
      return userInfo.signInUserSession.idToken.payload["cognito:groups"];
    }
  } catch (err) {
    console.log('error: ', err)
  }
}

export default {
  name: "AdminHome",
  setup() {

  },
  data() {
    return {
      isAdmin: false
    }
  },
  async mounted() {
    this.current_user_computed = await Auth.currentAuthenticatedUser();
    console.log("CURRENT USER COMPUTED: ", this.current_user_computed)
    this.isAdmin = !!this.current_user_computed.signInUserSession.idToken.payload['cognito:groups'].includes("Admin");
    return this.isAdmin;
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
