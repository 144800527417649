<template>
  <div>
  <h2>Commissions</h2>
  <v-img
      style="margin-left: 2em"
      class="float-md-right"
      width=400px
      :aspect-ratio="1"
      :src="`/site-images/Indiana-jimwcoleman.jpg`"
  ></v-img>
  <div style="text-align: justify">
  <p class="header">The question I have been asked most often through the years is this: Why haven't you always signed your real name to your artwork?</p>

  <p>First off, my work has nothing to do with the Cherokee Nation and I am not affiliated with the Cherokee Nation in any way.</p>

  <p>For decades, most of my work was simply signed "Cherokee," and I worked under the name "Cherokee Art." In the past several years, I've been moving away from that as it has been confusing to some. But to move completely away from the name would orphan hundreds of pieces of art that make up my portfolio. Regardless, out of respect for the Cherokee Nation and its long history, I am in the process of distancing myself from that name.</p>

  <p>Here's why. But to fully understand, I first have to tell you how it came to be that back in 1980, I first signed "Cherokee" to one of my art pieces ...</p>

  <p>Back then, I was working as a t-shirt artist, producing numerous Arizona-tourism t-shirt prints and starting to get some traction in the market. There was another artist in the market, who went by the name "Spyder." My boss, Tom, suggested that I come up with a pseudonym as well. Being very young, I had no idea what to choose, and I spouted off some ideas. Tom didn't like any of them. "You like drawing Native American work," he suggested. "Why don't you use Cherokee, or something similar?" As I did do some Native American themed art pieces back then, it made sense. And so it started.</p>

  <p>As my career progressed and my talents sharpened, I began to love the anonymity of working behind the pseudonym, particularly when exhibiting my work in galleries or local exhibits. It was great to be the artist but to be totally unrecognized while mingling during an exhibit. That, and I was always a bit shy, a bit uncertain of myself and not entirely sure that my work really belonged up on that wall or in that exhibit.</p>

  <p>I still love the anonymity but now, as I grow older and become more and more attentive to what I do and what I leave behind, I realize that my kids and grandchildren will likely appreciate having my real name signed to the work, particularly after I am gone. In the past several years, I've begun to move away from the "Cherokee" name and have, at last, become more comfortable in my own skin and with my own work.</p>
  </div>
  </div>
</template>

<script>
export default {
  name: "CherokeeArt"
}
</script>

<style scoped>
p {
  margin-top: 1em;
}
.header {
  font-family: sans-serif;
  font-size: 1.25em;
  color:olivedrab;
}
</style>
