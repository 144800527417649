<template>
  <div id="EditMerchandiseItem">
    <div v-if="isAdmin">
      <h2 v-if="new_artwork_item">Add Merchandise</h2>
      <h2 v-else>Edit {{ merch.title }}</h2>

      <v-container class="bg-surface-variant mb-4">
        <v-form ref="merchItemForm" lazy-validation @submit.prevent="trySave()">
          <v-row>
            <v-col>
              <file-pond
                  v-if="!merch.file_name"
                  name="gallery-image"
                  ref="pond"
                  label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
                  allow-multiple="false"
                  imagePreviewMaxHeight="200"
                  allowImageResize="true"
                  imageResizeUpscale="false"
                  imageResizeTargetHeight="600"
                  imageResizeMode="cover"
                  accepted-file-types="image/jpeg, image/png"
                  :files="galleryFiles"
                  v-model="galleryFiles"
                  v-on:init="handleFilePondInit"

              />
              <div v-else>
                <v-img
                    style="margin-left: 2em"
                    class="float-md-right"
                    width=200px
                    :aspect-ratio="1"
                    :src="`https://jwc2023gallery42719-jwcgallery.s3.amazonaws.com/public/` + this.merch.file_name"
                ></v-img>
              </div>
              <div>
                <v-text-field label="Name"
                              dense v-model="merch.title"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="Name of gallery item as it should be displayed"
                              bg-color="whitesmoke"></v-text-field>
              </div>
              <div>
                <v-autocomplete
                    dense v-model="merch.relates_to"
                    label="Artwork relation"
                    :items="this.merches_list"
                    item-title="title"
                    item-value="id"
                ></v-autocomplete>
              </div>
              <div>
                <v-select
                    dense v-model="merch.artist"
                    label="Artist"
                    :items="['Jim W. Coleman','Daniel Field Schmidt']"
                ></v-select>
              </div>
              <div>
                <v-text-field label="Description"
                              dense v-model="merch.description"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="Provide description of gallery item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col width="33%">
            </v-col>
            <v-col width="33%">
            </v-col>
            <v-col width="33%">
              <div>

              </div>
            </v-col>
          </v-row>

          <v-row>

            <v-col width="33%">
            </v-col>
            <v-col width="33%">
            </v-col>
            <v-col width="33%">
              <div>

              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>
<!--                TAGS - ########################################################################################-->
                <v-combobox
                    dense v-model="merch.tags"
                    label="Tags"
                    chips
                    multiple
                    closable-chips
                    validate-on="blur"
                    hide-selected
                    :items=uniqueTags
                ></v-combobox>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>
                <v-text-field label="File name"
                              dense v-model="merch.file_name"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="File name of gallery item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
              <div>
                <v-text-field label="External URL"
                              dense v-model="merch.external_url"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="URL to external gallery item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>
                <v-text-field label="Memo"
                              dense v-model="merch.memo"
                              variant="underlined"
                              color="white"
                              placeholder="Private notes"
                              bg-color="whitesmoke"></v-text-field>
              </div>
              <div>
                <v-btn v-if="new_artwork_item" type="submit" color="primary">Create merchandise</v-btn>
                <v-btn v-else type="submit" color="primary">Update merchandise</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script setup>
import '@aws-amplify/ui-vue/styles.css';</script>


<script>
/* eslint-disable */

import {
  createGallery,
  updateGallery,

} from '@/graphql/mutations';
import {getGallery, listGalleries} from "@/graphql/queries";
import {API, Auth, Storage} from "aws-amplify";
import promise from "core-js/internals/document-all";
import {nextTick} from "vue";

// Import Vue FilePond and plugins
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginImageResize
)

export default {
  name: "EditGalleryItem",
  components: {
    FilePond
  },
  setup() {

  },
  async created() {
    try {
      await this.getGalleries();
    } catch (e) {
      console.log("Unable to get galleries - EditMerchItem: ", e)
    }
  },
  data() {
    return {
      new_id: 0,
      galleryFiles: [],
      newTagsArray: [],
      unique: [],
      originalTags: [],
      selectedItems: [],
      new_artwork_item: true,
      new_tag_item: false,
      tags_types: {},
      tags_list: [],
      merches: [],
      merches_list: [],
      tableDataRows: [],
      isAdmin: false,
      incoming: null,
      new_merch: {},
      merch: {
        PK: '',
        SK: '',
        type: 'Merch',
        title: '',
        file_name: '',
        external_url: '',
        artist: '',
        memo: '',
        original_tags: [],
        tag: '',
        location: '',
        relates_to: '',
      }
    }
  },
  computed: {
    onlyTags() {
      return [...new Set(this.tags_list.data.listGalleries.items.map(item => item.tag))]
    },
    uniqueTags() {
      return this.onlyTags.filter((value, index, self) => self.indexOf(value) === index);
    },
    // https://dev.to/callmekatootie/aws-appsync-with-amplify-how-to-ensure-a-field-in-a-type-is-unique-51hp *** IMPORTANTANT **
  },

  async mounted() {
    this.incoming = this.$route.params.id;
    this.tags_list = await API.graphql({
      query: listGalleries,
      variables: {filter: {type: {eq: "Tag"}}}
    });
    if (this.incoming) {
      this.new_merch_item = false;
      await this.loadMerchandise(this.incoming);
    }

    try {
      this.current_user_computed = await Auth.currentAuthenticatedUser();
      this.isAdmin = !!this.current_user_computed.signInUserSession.idToken.payload['cognito:groups'].includes("Admin");
      return this.isAdmin;
    } catch (err) {
      console.log("Error in list merch authentication check: ", err)
    }


  },
  methods: {
    handleFilePondInit() {
      console.log('FilePond has initialized');
      // example of instance method call on pond reference
      // this.galleryFiles = this.$refs.pond.getFiles();
      // console.log("Gallery files: ", this.galleryFiles)
      // this.galleryFiles[0].filename = this.merch.file_name;
    },
    async trySave() {
      // get this.merches.tags.items
      await this.createMerchandiseListing();
    },
    createTagListing(item) {

    },
    async loadMerchandise(id) {

      let i;
      const artworks = await API.graphql({
        query: getGallery,
        variables: {id: id}
      });
      this.merch = artworks.data.getGallery;
      this.merch.tags = [];
      // https://dgraph.io/docs/graphql/queries/and-or-not/
      const tags = await API.graphql({
        query: listGalleries,
        variables: {
          filter: {
            SK: {eq: this.merch.SK},
            tag: {eq: 'Tag'}
          }
        }
      });
      this.merches.tags = [];
      for (i in tags.data.listGalleries.items) {
        this.merch.tags.push(tags.data.listGalleries.items[i].title)
      }
      this.originalTags = [...this.merch.tags]
      if (this.merch.type === 'Tag') {
        this.new_tag_item = true;
        this.new_merch_item = false;
      }
    },

    async saveImageToS3() {
      if (!this.merch.file_name) {
        this.galleryFiles = this.$refs.pond.getFiles();

        if (this.galleryFiles.length > 0) {
          this.merch.file_name = this.galleryFiles[0].filename;
          try {
            await Storage.put(this.galleryFiles[0].filename, this.galleryFiles[0].file, {});
          } catch (error) {
            console.log("Error uploading file: ", error);
          }
        }
      }
    },

    async createMerchandiseListing() {
      let epoch = Date.now();
      await this.saveImageToS3();
      const {
        id,
        PK,
        SK,
        title,
        file_name,
        external_url,
        artist,
        type,
        memo,
        location,
        tag,
        relates_to
      } = this.merch;
      if (!title) return;
      let Merchandise = {
        id,
        PK,
        SK,
        title,
        file_name,
        external_url,
        artist,
        type,
        memo,
        location,
        tag,
        relates_to
      };

      if (this.new_merch_item) {
        this.new_merch = {
          PK,
          SK,
          title,
          file_name,
          external_url,
          artist,
          type,
          memo,
          location,
          tag,
          relates_to
        };
      }
      this.merches = [...this.merches, Merchandise];

      if (this.new_merch_item) {
        console.log("ATTEMPTING to CREATE merchandise item");
        this.new_merch.PK = 'ART#' + epoch;
        this.new_merch.SK = 'MERCH#' + epoch;
        try {
          let newly_submitted = await API.graphql({
            query: createGallery,
            variables: {input: this.new_merch}
          });
          if (newly_submitted) {
            let i;
            console.log("Merch Tags: ", this.merch.tags)
            for (i in this.merch.tags) {
              console.log("Title: ", this.merch.tags[i])
              let new_tag = {
                PK: this.new_merch.PK,
                SK: this.new_merch.SK + '#' + this.merch.tags[i],
                tag: String(this.merch.tags[i]),
                title: this.merch.title,
                artist: this.merch.artist,
                file_name: this.merch.file_name,
                location: 'home',
                type: 'Merch',
                relates_to: this.merch.relates_to
              }
              let newly_submitted_tag = await API.graphql({
                query: createGallery,
                variables: {input: new_tag}
              });
            }
          }
        } catch (e) {
          console.log("ERROR creating new merch", e)
        }
        await this.$router.push({name: 'list-merch-items'})
      } else {
        console.log("ATTEMPTING to UPDATE merchandise item ");

        let tags = this.merch.tags
        console.log('TAGS LENGTH: ', tags.length)
        for (let i = 0; i < tags.length; i++) {
          if (!this.originalTags.includes(tags[i])) {
            console.log("FOUND NEW TAG: ", tags[i])
            let new_tag = {
              PK: this.merch.pk,
              SK: 'MERCH#' + epoch + '#' + tags[i],
              title: this.merch.title,
              artist: this.merch.artist,
              file_name: this.merch.file_name,
              location: 'home',
              type: 'Merch',
              tag: tags[i],
              relates_to: this.merch.relates_to
            }
            let newly_submitted_tag = await API.graphql({
              query: createGallery,
              variables: {input: new_tag}
            });
          }
        }
        console.log("Final tags: " , this.merch.tags)

        // Update gallery item listing
        await API.graphql({
          query: updateGallery,
          variables: {input: Merchandise}
        });

        await this.$router.push({name: 'list-merch-items'})
        this.resetMerch();
      }
      },

      async  getGalleries()
      {
        // this.current_user = await Auth.currentUserInfo();
        this.current_user = await Auth.currentAuthenticatedUser();
        //This gives an array that contains the cognito user groups to test access.
        // console.log("current user: ", this.current_user.signInUserSession.idToken.payload['cognito:groups']);
        const artworks = await API.graphql({
          query: listGalleries,
          variables: {
            filter: { SK: {beginsWith: "ART"}, and: {PK: {beginsWith: "TAG"}}}
          },
        });
        this.merches_list = artworks.data.listGalleries.items;
        console.log("MERCHES LIST IN DROPDOWN: ", this.merches_list)
      },

      resetMerch()
      {
        this.merch.title = '';
        this.merch.file_name = '';
        this.merch.external_url = '';
        this.merch.artist = '';
        this.merch.type = '';
        this.merch.memo = '';
        this.merch.location = '';
        this.merch.tag = '';
        this.merch.relates_to ='';
      }
    }
}
</script>

<style scoped>
.theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #6fbd44;
}

.v-input--is-focused .v-input__slot {
  border: 2px solid #ffffff !important;
  border-bottom-color: rgba(0, 0, 0, 0.38) !important;
}

.v-row {
  background-color: white;
  color: navy;
}


</style>
