<template>
<!--  <CartAddRemove v-if="toAdd" :product="item"/>-->
<!--  {{item}}-->
  <v-btn type="button" @click="addOrRemove()"  class="cart-btn btn btn-sm btn-outline-secondary me-2">
    <div v-if="toAdd"><v-icon>mdi-cart-plus</v-icon></div>
    <div v-else><v-icon>mdi-cart</v-icon></div>
  </v-btn>
</template>

<script>
import CartAddRemove from './CartAddRemove.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
export default{
  props:['product'],
  components :{CartAddRemove},
  data(){
    return{
      toAdd:true,
      item:[]
    }
  },
  methods:{
    async addOrRemove(){
      this.item.qty = 1
      this.$store.commit('addRemoveCart',{product:this.item, toAdd:this.toAdd})
      let toastMSG
      this.toAdd?  toastMSG = 'Added to cart' :  toastMSG = 'Removed from cart'
      toast(toastMSG, {
        autoClose: 1000,
      });
      this.toAdd = !this.toAdd
    }
  },
  mounted(){
    // console.log("This product in CartBNT: ", this.product.data.result.sync_product)
    let cart = this.$store.state.cart
    // console.log("THIs store state", this.$store.state)
    let obj = cart.find(o => o.id === this.product.id)
    // console.log("product in CartBTN", this.product[0].data.result.sync_product.id)
    // console.log("obj in CartBTN", obj)
    if(obj){
      this.toAdd = false
      this.item = obj
    }else{
      this.item = this.product
      this.toAdd = true
    }

  }
}
</script>
<style>
.cart-btn{
  width:40px;
  height: 38px;
}
.plus-minus input{
  max-width: 50px;
}
</style>
