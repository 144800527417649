<template>
  <div class="displayGalleryItem">
    <v-container  fluid class="gallery_container">
          <v-row>
            <v-col cols="12" sm="6" >
              <v-img
                  style="margin-left: auto;margin-right: auto"
                  class="bg-white"
                  width=400px
                  :aspect-ratio="1"
                  :src="`https://jwc2023-dev52754-main.s3.amazonaws.com/public/${artwork.file_name}`"
              ></v-img>
<!--              <GalleryLightbox v-if="merches.length > 0" v-bind:merch_data = "merches"></GalleryLightbox>-->
            </v-col>

            <v-col cols="12" sm="6" >
              <div style="text-align:left">{{ artwork.artist }}</div>
              <div style="text-align:left;font-family: sans-serif;font-weight:bold;font-size:24px">{{ artwork.title }}
              </div>
              <div style="text-align:left">{{ artwork.art_width }}x{{ artwork.art_height }} in</div>
              <v-divider style="padding-bottom: 2em;padding-top:1em"></v-divider>
              <div v-if="artwork.original_price > 0  && artwork.disposition === 'Available'"
                   style="text-align:left;font-family: sans-serif;font-weight:bold;font-size:18px">
                ${{ artwork.original_price }}
              </div>
              <div v-else  style="text-align:left;font-family: sans-serif;font-weight:bold;font-size:18px"><strong>{{ artwork.disposition }}</strong></div>
              <div v-if="artwork.original_price > 0 && artwork.disposition !== 'SOLD'" style="text-align:left;padding-bottom: 2em;padding-top:1em">
                <v-btn color="gray" disabled>Purchase original</v-btn>
                <router-link to="/Contact">
                <v-btn color="primary">Inquire</v-btn></router-link>
              </div>
              <div v-if="artwork.reprint_price > 0" style="text-align:left;padding-bottom: 2em;padding-top:1em">
                <v-btn color="primary">Purchase reprint</v-btn>
                <v-btn color="secondary">Inquire</v-btn>
              </div>
              <div v-if="artwork.disposition === 'SOLD' && artwork.reprint_price ===0" style="text-align:left;padding-bottom: 2em;padding-top:1em">
              <v-btn color="secondary">Inquire</v-btn>
              </div>
              <div style="text-align:left;font-family: sans-serif;font-size:12px">{{ artwork.description }}</div>
            </v-col>
          </v-row>

    </v-container>
  </div>
</template>

<script>
import {getGallery, listGalleries} from "@/graphql/queries";
import {API} from "aws-amplify";
// import GalleryLightbox from "@/components/GalleryLightbox";

export default {
  name: "DisplayGalleryItem",
  components: {
    // GalleryLightbox
  },
  data() {
    return {
      incoming: null,
      SK: null,
      artwork: {},
      merches: {},
    }
  },
  async mounted() {
    this.incoming = this.$route.params.id;
    if (this.incoming) {
      await this.loadItemDetails(this.incoming);
      await this.loadRelatedMerch(this.incoming);
    }

  },

  methods: {
    // https://aws.amazon.com/graphql/graphql-dynamodb-data-modeling/
    async loadItemDetails(id) {
      const artworks = await API.graphql({
        query: getGallery,
        variables: {id: id}
      });
      this.artwork = artworks.data.getGallery;
      // Get main key number - not currently in use
      // let art_key = this.artwork.SK
      // let mySubString = art_key.substring(
      //     art_key.indexOf("#") + 1,
      //     art_key.lastIndexOf("#")
      // );

    },
    async loadRelatedMerch(id) {
      console.log("TRYING TO LOAD MERCH: ", id)
      //@TODO deconstruct id and reassemble to point to artwork id and not tag id.
      const merches = await API.graphql({
        query: listGalleries,
        variables: {
          filter: { relates_to: {eq: id}}
          // filter: { relates_to: {eq: id}, and: {type: {eq: "Merch"}}}
        },
      });
      this.merches = merches.data.listGalleries.items;
      console.log("THIS MERCHES ON: ", this.merches)

    }
  },
}
</script>

<style scoped>

</style>
