const axios = require('axios')

class DataService {
    constructor() {
        this.service_host = 'http://localhost:8080',
            this.config = {
                headers: {
                    'Access-Control-Allow-Origin': true,
                    'Authorization': 'Bearer 6Jsv8odeeoJEQeVv3bdaEJayvN7gUDJXu6ILlNJO'
                }
            }
    }

    async getProducts() {
        // curl -X GET 'https://api.printful.com/store/products'  -H 'Authorization: Bearer 6Jsv8odeeoJEQeVv3bdaEJayvN7gUDJXu6ILlNJO'
        // const response = await axios.get(this.service_host + '/store/products', this.config)
        const response = await axios.get(this.service_host + '/store/products', this.config)
        return response.data.result
    }

    async getProduct(id) {
        const product_response = await axios.get(this.service_host + '/sync/products/' + id, this.config)
        return product_response.data.result
    }

    async getCombinedProductInfo(id) {
        let promises = []
        let res = await axios.get(this.service_host + '/store/products/' + id, this.config)
        promises.push(res)
        // let results = res.data.result
        //     promises.push(axios.get(this.service_host + '/store/products/' + id, this.config))
        let allPromises = await Promise.all(promises)
        console.log("ALL PROMISES: ", allPromises)
        return allPromises


    }

    // async getCombinedProductInfo() {
    //    // https://stackoverflow.com/questions/54742698/merging-two-arrays-from-chained-axios-requests
    //    let productArray = []
    //    try{
    //    axios.get(this.service_host + '/store/products', this.config).then(products => {
    //       productArray = products.data.result
    //       // console.log("PRODUCT ARRAY: ", productArray)
    //       if(productArray.length > 1){
    //           console.log("ProductArray items: ", productArray)
    //           productArray.forEach(function(product, index) {
    //               console.log("PRODUCT: ", index)
    //               axios.get(this.service_host + '/store/products/' + product.id.toString(), this.config).then(product => {
    //                   productArray[index].product = product
    //                   console.log("Added product to array: ", productArray[index])
    //               })
    //               console.log("PRODUCT ARRAY TO RETURN: ", productArray)
    //               return productArray
    //       }, this)}
    //    })} catch (e) {
    //        console.log(e)
    //    }
    //     return productArray
    // }
}
const dataService = new DataService()
export {dataService}
