/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://wvrqd6x7nvhfbpqqytcnquefg4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hi7dbc3tw5farmgszdhwnxsnha",
    "aws_cognito_identity_pool_id": "us-east-1:c4f70818-8296-4a70-8b7f-ccddd3107d06",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nxH3lX41z",
    "aws_user_pools_web_client_id": "4nmuhoomk34gis151m34d8b8ho",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "jwc2023-20230920064223-hosting-prod-main",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d1oubcazk5233n.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Gallery",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "jwc2023-dev52754-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
