<template>
  <div id="EditGalleryItem">
    <div v-if="isAdmin">
      <h2 v-if="new_merch_item">Add gallery item</h2>
      <h2 v-else>Edit {{ artwork.title }}</h2>

      <v-container class="bg-surface-variant mb-4">
        <v-form ref="galleryItemForm" lazy-validation @submit.prevent="trySave()">
          <v-row>
            <v-col>
              <file-pond
                  v-if="!artwork.file_name"
                  name="gallery-image"
                  ref="pond"
                  label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
                  allow-multiple="false"
                  imagePreviewMaxHeight="200"
                  allowImageResize="true"
                  imageResizeUpscale="false"
                  imageResizeTargetHeight="600"
                  imageResizeMode="cover"
                  accepted-file-types="image/jpeg, image/png"
                  :files="galleryFiles"
                  v-model="galleryFiles"
                  v-on:init="handleFilePondInit"

              />
              <div v-else>
                <v-img
                    style="margin-left: 2em"
                    class="float-md-right"
                    width=200px
                    :aspect-ratio="1"
                    :src="`https://jwc2023-dev52754-main.s3.amazonaws.com/public/` + this.artwork.file_name"
                ></v-img>
              </div>
              <div>
                <v-text-field label="Name"
                              dense v-model="artwork.title"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="Name of gallery item as it should be displayed"
                              bg-color="whitesmoke"></v-text-field>
              </div>
              <div>
                <v-select
                    dense v-model="artwork.artist"
                    label="Artist"
                    :items="['Jim W. Coleman','Daniel Field Schmidt']"
                ></v-select>
              </div>
              <div>
                <v-text-field label="Description"
                              dense v-model="artwork.description"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="Provide description of gallery item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col width="33%">
              <div>
                <v-text-field label="Year"
                              dense v-model="artwork.year"
                              variant="underlined"
                              color="white"
                              placeholder="Year created"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
            <v-col width="33%">
              <div>
                <v-combobox
                    dense v-model="artwork.art_media"
                    placeholder="Art media"
                    :items=uniqueMedia
                ></v-combobox>
              </div>
            </v-col>
            <v-col width="33%">
              <div>
                <v-select
                    dense v-model="artwork.type"
                    label="Type"
                    :items="['Home','Tag','<empty>']"
                ></v-select>
              </div>
            </v-col>
          </v-row>

          <v-row>

            <v-col width="33%">
              <div>
                <v-text-field label="Item width"
                              dense v-model="artwork.art_width"
                              variant="underlined"
                              color="white"
                              placeholder="Width of item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
            <v-col width="33%">
              <div>
                <v-text-field label="Item height"
                              dense v-model="artwork.art_height"
                              variant="underlined"
                              color="white"
                              placeholder="Height of item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
            <v-col width="33%">
              <div>
                <v-select
                    dense v-model="artwork.location"
                    label="Location"
                    :items="['Home','Gallery']"
                ></v-select>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>
                <!--                TAGS - ########################################################################################-->
                <v-combobox
                    dense v-model="artwork.tags"
                    label="Tags"
                    chips
                    multiple
                    closable-chips
                    validate-on="blur"
                    hide-selected
                    :items=uniqueTags
                ></v-combobox>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>
                <v-text-field label="Gallery item URL"
                              dense v-model="artwork.url"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="URL of gallery item"
                              bg-color="whitesmoke"></v-text-field>
              </div>

              <div>
                <v-text-field label="File name"
                              dense v-model="artwork.file_name"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="File name of gallery item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
              <div>
                <v-text-field label="External URL"
                              dense v-model="artwork.external_url"
                              variant="underlined"
                              style="width:40em"
                              color="white"
                              placeholder="URL to external gallery item"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col width="33%">
              <div>
                <v-select
                    dense v-model="artwork.disposition"
                    label="Disposition"
                    :items="['SOLD','Available','Gifted','Private collection','Not available']"
                ></v-select>
              </div>
            </v-col>
            <v-col width="33%">
              <div>
                <v-text-field label="Owner"
                              dense v-model="artwork.owner"
                              variant="underlined"
                              color="white"
                              placeholder="Owner"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
            <v-col width="33%">
            </v-col>
          </v-row>

          <v-row>
            <v-col width="33%">
              <div>
                <v-text-field label="Original price"
                              dense v-model="artwork.original_price"
                              variant="underlined"
                              color="white"
                              placeholder="Original price"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
            <v-col width="33%">
              <div>
                <v-text-field label="Sale price"
                              dense v-model="artwork.sale_price"
                              variant="underlined"
                              color="white"
                              placeholder="Sale price"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
            <v-col width="33%">
            </v-col>
          </v-row>

          <v-row width="33%">
            <v-col width="33%">
            </v-col>
            <v-col width="33%">
              <v-select
                  dense v-model="artwork.reprints_source"
                  label="Reprint source"
                  :items="['None','Artist','External']"
              ></v-select>
            </v-col>
            <v-col width="33%">
              <div>
                <v-text-field label="Reprint price"
                              dense v-model="artwork.reprint_price"
                              variant="underlined"
                              color="white"
                              placeholder="Reprint price"
                              bg-color="whitesmoke"></v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>
                <v-text-field label="Commissioned by"
                              dense v-model="artwork.commissioned_by"
                              variant="underlined"
                              color="white"
                              placeholder="Commissioned by"
                              bg-color="whitesmoke"></v-text-field>
              </div>

              <div>
                <v-text-field label="Memo"
                              dense v-model="artwork.memo"
                              variant="underlined"
                              color="white"
                              placeholder="Private notes"
                              bg-color="whitesmoke"></v-text-field>
              </div>
              <div>
                <v-btn v-if="new_merch_item" type="submit" color="primary">Create artwork</v-btn>
                <v-btn v-else type="submit" color="primary">Update artwork</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>
  </div>
</template>

<script setup>
import '@aws-amplify/ui-vue/styles.css';</script>


<script>
/* eslint-disable */

import {
  createGallery,
  updateGallery,

} from '@/graphql/mutations';
import {getGallery, listGalleries} from "@/graphql/queries";
import {API, Auth, Storage} from "aws-amplify";
import promise from "core-js/internals/document-all";
import {nextTick} from "vue";

// Import Vue FilePond and plugins
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginImageResize
)

// // These were throwing 'Uncaught TypeError: FilePond.registerPlugin is not a function'
// FilePond.registerPlugin(FilePondPluginImageResize);
// FilePond.registerPlugin(FilePondPluginImagePreview);
// FilePond.registerPlugin(FilePondPluginFileValidateType);

export default {
  name: "EditGalleryItem",
  components: {
    FilePond
  },
  setup() {

  },
  async created() {
    try {
      await this.getGalleries();
    } catch (e) {
      console.log("Unable to get galleries - EditGalleryItem: ", e)
    }
  },
  data() {
    return {
      new_id: 0,
      galleryFiles: [],
      newTagsArray: [],
      selectedItems: [],
      new_merch_item: true,
      new_tag_item: false,
      media_types: [],
      tags_types: {},
      tags_list: [],
      artworks: [],
      artworks_list: [],
      tableDataRows: [],
      isAdmin: false,
      incoming: null,
      new_artwork: {},
      artwork: {
        PK: '',
        SK: '',
        type: '',
        title: '',
        description: '',
        url: '',
        file_name: '',
        external_url: '',
        year: '',
        art_width: 0,
        art_height: 0,
        disposition: '',
        artist: '',
        art_media: '',
        owner: '',
        original_price: 0.0,
        sale_price: 0.0,
        reprints_source: '',
        reprint_price: 0.0,
        commissioned_by: '',
        memo: '',
        original_tags: [],
        tag: '',
        location: 'Gallery'
      }
    }
  },
  computed: {
    onlyMedia() {
      return this.artworks_list.map(dataItem => dataItem.art_media);
    },
    uniqueMedia() {
      this.media_types = this.onlyMedia.filter((value, index, self) => self.indexOf(value) === index);
      console.log("mEdia Types: ", this.media_types)
      return this.media_types
    },
    onlyTags() {
      return [...new Set(this.tags_list.data.listGalleries.items.map(item => item.tag))]
    },
    uniqueTags() {
      return this.onlyTags.filter((value, index, self) => self.indexOf(value) === index);
    },
    // https://dev.to/callmekatootie/aws-appsync-with-amplify-how-to-ensure-a-field-in-a-type-is-unique-51hp *** IMPORTANTANT **
  },

  async mounted() {
    this.incoming = this.$route.params.id;
    this.tags_list = await API.graphql({
      query: listGalleries,
      variables: {filter: {type: {eq: "Tag"}}}
    });
    if (this.incoming) {
      this.new_merch_item = false;
      await this.loadArtwork(this.incoming);
    }

    try {
      this.current_user_computed = await Auth.currentAuthenticatedUser();
      this.isAdmin = !!this.current_user_computed.signInUserSession.idToken.payload['cognito:groups'].includes("Admin");
      return this.isAdmin;
    } catch (err) {
      console.log("Error in list artwork authentication check: ", err)
    }


  },
  methods: {
    handleFilePondInit() {
      console.log('FilePond has initialized');
      // example of instance method call on pond reference
      // this.galleryFiles = this.$refs.pond.getFiles();
      // console.log("Gallery files: ", this.galleryFiles)
      // this.galleryFiles[0].filename = this.artwork.file_name;
    },
    async trySave() {
      // get this.artworks.tags.items
      await this.createArtworkListing();
    },
    createTagListing(item) {

    },
    async loadArtwork(id) {

      let i;
      const artworks = await API.graphql({
        query: getGallery,
        variables: {id: id}
      });
      this.artwork = artworks.data.getGallery;
      this.artwork.tags = [];
      // https://dgraph.io/docs/graphql/queries/and-or-not/
      const tags = await API.graphql({
        query: listGalleries,
        variables: {
          filter: {
            SK: {eq: this.artwork.SK},
            tag: {eq: 'Tag'}
          }
        }
      });
      this.artworks.tags = [];
      for (i in tags.data.listGalleries.items) {
        this.artwork.tags.push(tags.data.listGalleries.items[i].title)
      }
      this.originalTags = [...this.artwork.tags]
      if (this.artwork.type === 'Tag') {
        this.new_tag_item = true;
        this.new_merch_item = false;
      }
    },

    async saveImageToS3() {
      if (!this.artwork.file_name) {
        this.galleryFiles = this.$refs.pond.getFiles();

        if (this.galleryFiles.length > 0) {
          this.artwork.file_name = this.galleryFiles[0].filename;
          try {
            await Storage.put(this.galleryFiles[0].filename, this.galleryFiles[0].file, {});
          } catch (error) {
            console.log("Error uploading file: ", error);
          }
        }
      }
    },

    async createArtworkListing() {
      let epoch = Date.now();
      await this.saveImageToS3();
      const {
        id,
        PK,
        SK,
        title,
        description,
        url,
        file_name,
        external_url,
        year,
        art_width,
        art_height,
        disposition,
        artist,
        art_media,
        owner,
        original_price,
        sale_price,
        reprints_source,
        reprint_price,
        commissioned,
        commissioned_by,
        type,
        memo,
        location,
        tag
      } = this.artwork;
      if (!title) return;
      let artwork = {
        id,
        PK,
        SK,
        title,
        description,
        url,
        file_name,
        external_url,
        year,
        art_width,
        art_height,
        disposition,
        artist,
        art_media,
        owner,
        original_price,
        sale_price,
        reprints_source,
        reprint_price,
        commissioned,
        commissioned_by,
        type,
        memo,
        location,
        tag
      };

      if (this.new_merch_item) {
        this.new_artwork = {
          PK,
          SK,
          title,
          description,
          url,
          file_name,
          external_url,
          year,
          art_width,
          art_height,
          disposition,
          artist,
          art_media,
          owner,
          original_price,
          sale_price,
          reprint_price,
          reprints_source,
          commissioned_by,
          type,
          memo,
          location,
          tag
        };
      }
      this.artworks = [...this.artworks, artwork];

      if (this.new_merch_item) {
        console.log("ATTEMPTING to CREATE Gallery item - PK, SK and 'empty' tag");

        this.new_artwork.PK = 'ART#' + epoch;
        this.new_artwork.SK = 'ART#' + epoch;
        this.new_artwork.tag = '<empty>';
        console.log("This new_artwork: ", this.new_artwork);
        console.log("This artwork: ", this.artwork);
        try {
          console.log("Trying to create newly submitted on line 575")
          let newly_submitted = await API.graphql({
            query: createGallery,
            variables: {input: this.new_artwork}
          });
          // this.new_id = newly_submitted.data.createArtwork.id;
          console.log("newly subitted: ", newly_submitted)
          if (newly_submitted) {
            let i;
            console.log("Newly submitted artwork Tags: ", this.artwork.tags)
            for (i in this.artwork.tags) {
              console.log("Iteration of artwork tags: ", this.artwork.tags[i])
              let new_tag = {
                PK: 'TAG#' + epoch,
                SK: this.new_artwork.PK + this.artwork.tags[i],
                tag: String(this.artwork.tags[i]),
                title: this.artwork.title,
                artist: this.artwork.artist,
                art_media: this.artwork.art_media,
                art_width: this.artwork.art_width,
                art_height: this.artwork.art_height,
                disposition: this.artwork.disposition,
                original_price: this.artwork.original_price,
                file_name: this.artwork.file_name,
                year: this.artwork.year,
                description: this.artwork.description,
                location: this.artwork.location,
                type: this.artwork.type
              }
              let newly_submitted_tag = await API.graphql({
                query: createGallery,
                variables: {input: new_tag}
              });
              console.log("newly_submitted_tag: ", newly_submitted_tag)
            }
          } else {
            console.log("Newly submitted createGallery failed")
          }
        } catch (e) {
          console.log("ERROR creating new artwork", e)
        }
        await this.$router.push({name: 'list-gallery-items'})
      } else {
        console.log("ATTEMPTING to UPDATE gallery item ");

        let tags = this.artwork.tags
        console.log('TAGS LENGTH: ', tags.length)
        for (let i = 0; i < tags.length; i++) {
          if (!this.originalTags.includes(tags[i])) {
            console.log("FOUND NEW TAG: ", tags[i])
            let new_tag = {
              PK: 'TAG#' + epoch,
              SK: this.artwork.PK + '#' + tags[i],
              title: this.artwork.title,
              artist: this.artwork.artist,
              disposition: this.artwork.disposition,
              original_price: this.artwork.original_price,
              file_name: this.artwork.file_name,
              art_media: this.artwork.art_media,
              art_width: this.artwork.art_width,
              art_height: this.artwork.art_height,
              year: this.artwork.year,
              description: this.artwork.description,
              location: this.artwork.location,
              type: this.artwork.type,
              tag: tags[i]
              // tag: 'Celeb'
            }
            let newly_submitted_tag = await API.graphql({
              query: createGallery,
              variables: {input: new_tag}
            });
          }
        }
        console.log("Final tags: " , this.artwork.tags)

        // Update gallery item listing
        await API.graphql({
          query: updateGallery,
          variables: {input: artwork}
        });

        await this.$router.push({name: 'list-gallery-items'})
        this.resetArtwork();
      }
    },

    async  getGalleries()
    {
      // this.current_user = await Auth.currentUserInfo();
      this.current_user = await Auth.currentAuthenticatedUser();
      //This gives an array that contains the cognito user groups to test access.
      // console.log("current user: ", this.current_user.signInUserSession.idToken.payload['cognito:groups']);
      const artworks = await API.graphql({
        query: listGalleries
      });
      this.artworks_list = artworks.data.listGalleries.items;
    },

    resetArtwork()
    {
      this.artwork.title = '';
      this.artwork.description = '';
      this.artwork.url = '';
      this.artwork.file_name = '';
      this.artwork.external_url = '';
      this.artwork.year = '';
      this.artwork.art_width = 0
      this.artwork.art_height = 0
      this.artwork.disposition = '';
      this.artwork.artist = '';
      this.artwork.art_media = '';
      this.artwork.owner = '';
      this.artwork.original_price = 0.0;
      this.artwork.sale_price = 0.0;
      this.artwork.reprint_price = 0.0;
      this.artwork.reprints_source = '';
      this.artwork.commissioned_by = '';
      this.artwork.type = '';
      this.artwork.memo = '';
      this.artwork.location = '';
      this.artwork.tag = '';
    }
  }
}
</script>

<style scoped>
.theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot:hover {
  border-width: 1px;
  border-style: solid;
  border-color: #6fbd44;
}

.v-input--is-focused .v-input__slot {
  border: 2px solid #ffffff !important;
  border-bottom-color: rgba(0, 0, 0, 0.38) !important;
}

.v-row {
  background-color: white;
  color: navy;
}


</style>
