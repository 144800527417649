/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGallery = /* GraphQL */ `
  query GetGallery($id: ID!) {
    getGallery(id: $id) {
      id
      PK
      SK
      type
      description
      url
      file_name
      external_url
      year
      art_width
      art_height
      disposition
      artist
      art_media
      owner
      original_price
      sale_price
      reprint_price
      reprints_source
      commissioned_by
      memo
      title
      location
      tag
      relates_to
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGalleries = /* GraphQL */ `
  query ListGalleries(
    $filter: ModelGalleryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGalleries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        PK
        SK
        type
        description
        url
        file_name
        external_url
        year
        art_width
        art_height
        disposition
        artist
        art_media
        owner
        original_price
        sale_price
        reprint_price
        reprints_source
        commissioned_by
        memo
        title
        location
        tag
        relates_to
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
