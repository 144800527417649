<template>
  <section class="h-100 h-custom min-h-content">
    <v-container fluid class="gallery_container">
      <v-row>
        <v-col
            cols="12"
            sm="12"
        >
          <h5 class="mb-3">
            <router-link :to="{name:'Home'}" class="text-body"><i
                class="fas fa-long-arrow-alt-left me-2"></i>Continue shopping
            </router-link>
          </h5>
          <hr>
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div>
              <p class="mb-0">You have {{ $store.state.cart.length }} items in your cart</p>
            </div>
          </div>

          <v-table density="compact">
            <thead>
              <tr>
                <th class="text-left">
                </th>
                <th class="text-left">
                </th>
                <th class="text-left">
                </th>
                <th class="text-left">
                </th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="item in $store.state.cart" :key="item.id">
              <td>
<!--                {{item}}-->
                <img
                    :src="item.files[0].thumbnail_url"
                    class="img-fluid rounded-3" alt="Shopping item" style="width: 65px;margin-top: auto;margin-bottom: auto">
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ formatMoney(item.retail_price) }}
              </td>
              <td>
                <v-icon icon="mdi-delete" @click="removeItem(item)"></v-icon>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-col>

        <v-col
            cols="12"
            sm="12">
          <div class="col-lg-5">
            <div style="background:#2196f3; color: white; border-radius: 10px" class="pa-3">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-4">
                  <h5 class="ma-0">Cart details</h5>
                  <i class="bi bi-cart3 h1"></i>
                </div>
                <hr class="my-4">
                <div class="d-flex justify-content-between">
                  <p class="ma-2">Subtotal:</p>
                  <p class="ma-2"><i class="bi bi-currency-dollar"></i>{{ formatMoney(parseFloat($store.state.cartTotal)) }}</p>
                </div>
                <div class="d-flex justify-content-between mb-4">
                  <p class="ma-2">Total:</p>
                  <p class="ma-2"><i class="bi bi-currency-dollar"></i>{{ formatMoney(parseFloat($store.state.cartTotal)) }}</p>
                </div>

                <v-btn type="button" class="ma-2;pa-1">
                  Checkout
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import CartAddRemove from '../cart/CartAddRemove.vue';
import filters from '@/filters'

export default {
  components: {
    CartAddRemove
  },
  methods: {
    ...filters.filters,
    removeItem(item) {
      console.log("REMOVING ITEM: ", item)
      this.$store.commit('addRemoveCart', {product: item, toAdd: false})
    },
  },
  mounted() {

  }

}
</script>
