<template>
  <div class="ma-5 topnav">
    {{gallery_type}}
  </div>

{{artworks_list}}
  <v-container fluid class="gallery_container">
    <v-row no-gutters>
      <v-col
          v-for="item in state.artworks"
          :key="item.title"
          cols="12"
          sm="3"
      >
        <v-sheet class="ma-2 pa-2">
          <v-card style="max-width: 220px; padding-bottom: 0;margin-bottom: 0">
            <router-link :to="{name:'gallery-display-item', params: {id: item.id }}" style="text-decoration: none">
              <v-img
                  style="margin-left: auto;margin-right: auto"
                  class="bg-white"
                  width=200px
                  :aspect-ratio="1"
                  :src="`https://jwc2023-dev52754-main.s3.amazonaws.com/public/${item.file_name}`"
              ></v-img>
            </router-link>
            <div class="gallery_items_header"><i>{{ item.title }}</i>, {{ item.year }}</div>
            <div class="gallery_items_text">{{ item.art_media }}</div>
            <div class="gallery_items_text">{{ item.artist }}</div>
            <div class="gallery_items_text" v-if="item.disposition !== 'Gifted' && item.disposition !== 'SOLD'">
              ${{ item.original_price }}
            </div>
            <div v-if="item.disposition === 'SOLD'" class="gallery_items_sold"><strong>{{ item.disposition }}</strong>
            </div>
            <div v-else class="gallery_items_text">{{ item.disposition }}</div>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { useRoute } from 'vue-router'
import {reactive, ref, watch} from 'vue'
import {API} from "aws-amplify";
// import {listGalleriesByTag} from "@/graphql/queries";
import {listGalleries} from "@/graphql/queries";

export default {
  name: "MainGallery",

  setup() {
    // onMounted(() => {
    //   this.getArtworks()
    // });
    const route = useRoute()
    const gallery_type = ref("")

    const state = reactive({
      artworks: []
    })

    async function getArtworks() {
      // This whole section could be refactored to one database call and parsed on the client.
      console.log("Gallery type: ", gallery_type.value)
      try {
        const artworks = await API.graphql({
          query: listGalleries,
          variables: {},

        });
        console.log("ARTWORKS: ", artworks)
        if (gallery_type.value !== "all") {
          state.artworks = artworks.data.listGalleries.items.filter((item) => item.tag === gallery_type.value && item.type === 'Tag')
          if (gallery_type.value === "Painting") {
            gallery_type.value = "Paintings are oil, acrylic or watercolor and are created with Golden, Winsor & Newton, Grumbacher or Liquitex paints for brilliant, durable longevity."
          }
          if (gallery_type.value === "Pen-and-ink") {
            gallery_type.value = "Hand-drawn using Koh-i-noor rapidograph pens and the finest India inks on durable Cresent board stock for durability and ease of framing."
          }
          if (gallery_type.value === "Celeb") {
            gallery_type.value = "Many celebrities have embraced and graciously signed my work. See how many names here you recognize. More are on the way as I can get them scanned."
          }
        } else {
          state.artworks = artworks.data.listGalleries.items.filter((item) => item.tag !== "Merch" && item.type === 'Tag')
          gallery_type.value = "All gallery items, whimsically displayed in no particular order."
        }
        console.log("STATE ARTWORKS: ", state.artworks)

      } catch (e) {
        console.log("Error getting gallery by tag: ", e)
      }

    }

    watch(() => route.params.name, () => {
      if (route.params.name) {
        gallery_type.value = route.params.name
        getArtworks()
      }
    }, {immediate: true})


    return {state, gallery_type}
  },

  methods: {}
}
</script>

<style scoped>
.gallery_container {
  width: 1200px;
}
.gallery_items_text {
  font-size: .85em;
  color: darkmagenta;
  text-decoration: none;
}
.gallery_items_header {
  font-size: .85em;
  color: darkslategrey;
  text-decoration: none;
}
.gallery_items_sold {
  font-size: 1em;
  color: darkslategrey;
  text-decoration: none;
}</style>
